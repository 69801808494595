<template>
    <div>
        <el-card>
            <!-- 搜索 添加 -->
            <el-row :gutter="20">
                <el-col :span="5">
                <el-input v-model="queryInfo.query" placeholder="请输入内容"  >
                    <el-button slot="append" icon="el-icon-search" @click="getTableData"></el-button>
                </el-input>
                </el-col>
                <el-col :span="2">
                </el-col>
                <el-col :span="2">
                  <el-select v-model="queryInfo.querySelectInput" @change="getTableData" placeholder="条件选择">
                    <el-option
                      v-for="item in querySelectOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-button type="primary" @click="addPd"  >新增违约</el-button>
                  <el-button type="primary" @click="deletPd" :disabled="isDisabled">取消违约</el-button>
                  <el-button type="primary" @click="submitPd" :disabled="isDisabled">提交</el-button>
                </el-col>
            </el-row>
            <el-table
            :data="tableData"  highlight-current-row
            @current-change="pdCurrentChange"
            border stripe>
                <el-table-column fixed
                prop="company_ID"
                label="公司ID"
                width="150">
                </el-table-column>
                <el-table-column fixed
                prop="company_NAME"
                label="公司名称"
                width="240">
                </el-table-column>
                <el-table-column fixed
                prop="init_DATE"
                label="发生日期"
                width="150">
                </el-table-column>
                <el-table-column fixed
                prop="default_DATE"
                label="违约日期"
                width="150">
                </el-table-column>
                <el-table-column fixed
                prop="task_STATUS_NAME"
                label="任务状态"
                width="150">
                </el-table-column>
                <el-table-column fixed
                prop="bond_DEFAULT_NAME"
                label="债券违约"
                width="150">
                </el-table-column>
                <el-table-column fixed
                  prop="bond_SNAME"
                  label="债券简称"
                  width="150" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column fixed
                prop="remark"
                label="违约描述"
                width="150" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column fixed
                prop="process_OPINION"
                label="意见"
                width="150" :show-overflow-tooltip="true">
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pagenum"
              :page-sizes="[2, 5, 10, 15]"
              :page-size="queryInfo.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totle"
            ></el-pagination>
        </el-card>
        <el-dialog title="新增违约信息" :visible.sync="addDialogVisible" width="40%"
          :before-close="closeRegDialog">
             <el-form ref="changeForm" :model="changeForm"  label-width="80px" :rules="rules" class="demo-ruleForm" >
                <el-form-item label="公司名称" prop="company_ID" >
                  <el-select clearable style="width:280px" @change="queryBondNodeById" v-model="changeForm.company_ID" :filter-method="userFilter" filterable placeholder="请选择">
                    <el-option
                      v-for="item in company_options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="发生日期" prop="init_DATE">
                    <div class="block">
                    <el-date-picker
                        v-model="changeForm.init_DATE"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        @change="comppareDate(1)"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="违约日期" prop="default_DATE">
                    <div class="block">
                    <el-date-picker
                        v-model="changeForm.default_DATE"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        @change="comppareDate(2)"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="债券违约" prop="bond_DEFAULT">
                  <el-select v-model="changeForm.bond_DEFAULT" @change="queryBondNodeById" placeholder="请选择">
                      <el-option
                        v-for="item in bondDefaultOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </el-form-item>
                <div v-if="changeForm.bond_DEFAULT==1 && changeForm.company_ID != ''">
                  <el-form-item label="债券名称" prop="bond_ID" >
                    <el-select clearable style="width:280px" v-model="changeForm.bond_ID" :filter-method="userFilterBond" filterable placeholder="请选择">
                      <el-option
                        v-for="item in bond_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="违约描述"  prop="remark">
                    <el-input :autosize="{ minRows: 4}" type="textarea" v-model="changeForm.remark"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button @click="save('changeForm')" type="primary" >提交审核</el-button>
                  <el-button @click="cancle">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: 'CompanyPd',
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/company/pd', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
      this.rowData = null
      // this.violateValue = ''
    },
    async queryBondNodeById () {
      const { data: res } = await this.$http.get('query/bond/node/id', {
        params: this.changeForm
      })
      this.all_bond_options = res
      this.changeForm.bond_ID = ''
      this.userFilterBond()
    },
    async queryCompanyNode () {
      const { data: res } = await this.$http.get('query/company/node')
      this.all_company_options = res
      this.userFilter()
    },
    userFilterBond (query = '') {
      const arr = this.all_bond_options.filter((item) => {
        return item.label.includes(query) || item.value.includes(query)
      })
      if (arr.length > 50) {
        this.bond_options = arr.slice(0, 50)
      } else {
        this.bond_options = arr
      }
    },
    userFilter (query = '') {
      const arr = this.all_company_options.filter((item) => {
        return item.label.includes(query) || item.value.includes(query)
      })
      if (arr.length > 50) {
        this.company_options = arr.slice(0, 50)
      } else {
        this.company_options = arr
      }
    },
    async save (formName) {
      let istrue
      // form验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          istrue = true
        } else {
          return false
        }
      })
      if (istrue) {
        const { data: res } = await this.$http.post('insert/company/pd', this.changeForm)
        if (res.have) {
          this.$message.success('该任公司已被评为违约，无需再次提交')
        } else {
          this.addDialogVisible = false
        }
        this.getTableData()
      }
      this.cleanForm()
    },
    addPd () {
      this.queryCompanyNode()
      this.addDialogVisible = true
    },
    cleanForm () {
      this.changeForm.company_ID = ''
      this.changeForm.company_NAME = ''
      this.changeForm.remark = ''
      this.changeForm.init_DATE = ''
      this.changeForm.default_DATE = ''
      this.changeForm.bond_DEFAULT = ''
      this.changeForm.bond_ID = ''
    },
    // 取消并清空数据
    cancle () {
      this.cleanForm()
      this.addDialogVisible = false
    },
    // 关闭并清空数据
    closeRegDialog () {
      this.cleanForm()
      this.addDialogVisible = false
    },
    // 比较日期 违约日期大于等于发生日期
    comppareDate (value) {
      if (value === 1) {
        const date = new Date(this.changeForm.init_DATE)
        date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
        const moment = require('moment')
        this.changeForm.default_DATE = moment(date).format('YYYY-MM-DD')
      }
      if (!this.compparThisData) {
        this.$alert('违约日期不能大于当天', '信息提示').then(() => {
          this.changeForm.default_DATE = ''
          if (value === 1) {
            this.changeForm.init_DATE = ''
          }
        }).catch(() => {
          this.changeForm.default_DATE = ''
        })
      }
      if (this.checkDefaultDate && this.changeForm.default_DATE !== '' && this.changeForm.init_DATE !== '') {
        this.$alert('违约日期不能小于发生日期', '信息提示').then(() => {
          if (value === 2) {
            this.changeForm.default_DATE = ''
          } else {
            this.changeForm.init_DATE = ''
          }
        }).catch(() => {
        })
      }
    },
    // 删除违约
    comfirmDeletPd () {
    },
    async deletPd () {
      if (this.rowData == null) {
        this.$alert('请选择一条公司信息', '信息选择').then(() => {
        }).catch(() => {
        })
        return
      }
      await this.$http.post('change/company/pd', {
        task_ID: this.deleteInfo.task_ID
      })
      this.getTableData()
    },
    async submitPd () {
      if (this.rowData == null) {
        this.$alert('请选择一条公司信息', '信息选择').then(() => {
        }).catch(() => {
        })
        return
      }
      await this.$http.post('submit/company/pd', {
        task_ID: this.deleteInfo.task_ID
      })
      this.getTableData()
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    // 点击一条公司信息
    pdCurrentChange (val) {
      if (val != null) {
        this.deleteInfo.task_ID = val.task_ID
        this.rowData = val
        if (val.task_STATUS === 0) {
          this.isDisabled = false
        } else {
          this.isDisabled = true
        }
      }
    }
  },
  created () {
    this.getTableData()
  },
  computed: {
    checkDefaultDate: function () {
      return this.changeForm.default_DATE < this.changeForm.init_DATE
    },
    compparThisData: function () {
      const thisdate = new Date()
      const date = new Date(this.changeForm.default_DATE)
      return thisdate.getTime() > date.getTime()
    }
  },
  data () {
    return {
      addDialogVisible: false,
      isDisabled: true,
      all_company_options: [],
      company_options: [],
      all_bond_options: [],
      bond_options: [],
      rowData: null,
      bondDefaultOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      tableData: [],
      totle: 0,
      deleteInfo: {
        pd_ID: '',
        task_ID: ''
      },
      queryInfo: {
        query: '',
        // 当前页数
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      rules: {
        company_ID: [
          { required: true, message: '公司名称', trigger: 'change' }
        ],
        bond_ID: [
          { required: true, message: '债券名称', trigger: 'change' }
        ],
        init_DATE: [
          { required: true, message: '发生日期', trigger: 'change' }
        ],
        default_DATE: [
          { required: true, message: '违约日期', trigger: 'change' }
        ],
        bond_DEFAULT: [
          { required: true, message: '债券违约', trigger: 'change' }
        ],
        remark: [
          { required: false, message: '违约描述', trigger: 'change' }
        ]
      },
      value1: '',
      textarea: '',
      querySelectOptions: [{
        value: '0',
        label: '退回'
      }, {
        value: '1',
        label: '待处理'
      }, {
        value: '2',
        label: '审核通过'
      }, {
        value: '9',
        label: '取消'
      }, {
        value: '',
        label: '全部'
      }],
      changeForm: {
        company_ID: '',
        bond_ID: '',
        company_NAME: '',
        remark: '',
        bond_DEFAULT: '',
        init_DATE: '',
        default_DATE: '',
        task_STATUS: '',
        arr: []
      }
    }
  }

}
</script>
<style lang="less" >
.el-table__body tr.current-row>td {
    color: #fff;
    background-color: #a2a4a7!important;
}
</style>
